
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Types } from '../../types.domain';
import { GetInviteServiceInterface } from '../interfaces/services/get-invite-service.interface';
import { GetInviteInput } from '../dtos/get-invite.input';
import { GetInviteOutput } from '../dtos/get-invite.output';

@Injectable()
export class GetInviteService implements GetInviteServiceInterface {
  private readonly _http = inject(HttpClient);
  private readonly _settings = inject(Types.Settings);

  async handle(input: GetInviteInput): Promise<GetInviteOutput> {
    const requestUrl = `${this._settings.signManagerUrl}/invite/v1/${input.id}/documents`;
    return lastValueFrom(this._http.get<GetInviteOutput>(requestUrl ));
  }
}
