import { Injectable, inject } from '@angular/core';

import { Types } from '../../types.domain';
import { AuthenticationCallbackInput } from '../dtos/authentication-callback.input';
import { AuthenticationCallbackOutput } from '../dtos/authentication-callback.output';
import { AuthenticationCallbackRepositoryInterface } from '../interfaces/repositories/authentication-callback.repository.interface';
import { GetAccessTokenServiceInterface } from '../interfaces/services/get-access-token.service.interface';
import { GetAccessTokenInput } from '../dtos/get-access-token.input';
import { GetAccessTokenOutput } from '../dtos/get-access-token.output';

@Injectable()
export class GetAccessTokenService implements GetAccessTokenServiceInterface {
  private readonly _authenticationRepository = inject<AuthenticationCallbackRepositoryInterface>(
    Types.AuthenticationCallbackRepository
  );

  async handle(input: GetAccessTokenInput): Promise<GetAccessTokenOutput> {
    return await this._authenticationRepository.generateAccesstoken(input);
  }
}
